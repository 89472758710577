import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import { connect } from "react-redux";
import { createMember, updateMember } from "../redux/actions/members";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

export default connect(null, { createMember, updateMember })(FormComponent);
function FormComponent( props ) {
    const initialForm = {
        name: '',
        email: '',
        contact: '',
    }
    const [form, setForm] = useState(initialForm);
    const [vname, setVname] = useState(true);
    const [vemail, setVemail] = useState(true);
    const [vcontact, setVcontact] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const regexEmail = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regexPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    const handleChange = (prop) => (event) => {
        setForm({ ...form, [prop]: event.target.value });
        if ( submitted ) {
            if (regexEmail.test(form.email)) {
                setVemail(false)
            } 
            if (regexPhone.test(form.contact)) {
                setVcontact(false)
            } 
            if (form.name.length > 2) {
                setVname(false)
            } 
        }
    };

    const handleClick = async () => {
        await setSubmitted(true) 
        if (regexEmail.test(form.email)) {
            setVemail(false)
        }
        if (regexPhone.test(form.contact)) {
            setVcontact(false)
        }
        if (form.name.length > 2) {
            setVname(false)
        }
        if ( regexEmail.test(form.email) && regexPhone.test(form.contact) && form.name.length > 2 ) {
            const memberForm = { ...form, complete: false }
            if ( props.formMode === 'Create' ) {
                createMember(memberForm)
            } else if (props.formMode === 'Update') {
                updateMember(memberForm)
            }
        }
    }

    const ressetValidate = () => {
        setSubmitted(false) 
        setVname(true)
        setVemail(true)
        setVcontact(true)
    }

    

    const createMember = (params) => {
        props.createMember(params)
        .then( res => {
            setForm({name: '', email: '', contact: ''})
            ressetValidate()
            props.setToastr({isShowSuccess: true, massage: res.status })
        })
        .catch( err => {
            props.setToastr({isShowError: true, massage: err.status })
        })
    }

    const updateMember = (params) => {
        props.updateMember(params.id, params)
        .then( res => {
            setForm({name: '', email: '', contact: ''})
            ressetValidate()
            props.setToastr({isShowSuccess: true, massage: res.status })
            props.onClick()
        })
        .catch( err => {
            props.setToastr({isShowError: true, massage: err.status })
        })
    }

    useEffect((form) => {
       if(props.formUpdate) {
           const setFormValue = {...form, ...props.formUpdate };
           const contact = props.formUpdate?.contact.trim()
           setForm({ ...setFormValue, contact })
       }
    }, [props.formUpdate]);

    return (
        <Box component={Paper} sx={{ display: 'flex', flexWrap: 'wrap', p: 4 }}>
            <div className="animated fadeIn" style={{ width: '100%'}}>
                <Typography variant="h6" component="div">
                    { props.formMode === 'Update' ? <p>Update MemberId: { props.formUpdate.uid }</p> : props.formMode === 'Create' ? <p>Create Member</p> : false }
                </Typography>
                <FormControl  fullWidth sx={{ my: 2 }}>
                    <TextField
                        error={ (vname && submitted) }
                        id="name"
                        type="text"
                        label={ (vname && submitted) ? 'Error name is required or invalid.': 'Name' }
                        value={form.name}
                        onChange={handleChange('name')}
                        required
                    />
                </FormControl>
                <FormControl fullWidth sx={{ my: 2 }}>
                    <TextField
                        error={(vemail&& submitted) }
                        id="email"
                        type="email"
                        label={ (vemail&& submitted)  ? 'Error email is required or invalid.': 'Email' }
                        value={form.email}
                        onChange={handleChange('email')}
                        required
                    />
                </FormControl>
                <FormControl fullWidth sx={{ my: 2, mb: 2 }}>
                    <TextField
                        error={(vcontact&& submitted)}
                        id="contact"
                        type="number"
                        label={ (vcontact&& submitted) ? 'Error contact is required or invalid.': 'Contact' }
                        value={form.contact}
                        onChange={handleChange('contact')}
                        required
                    />
                </FormControl>
                <div style={{ float: 'right' }}>
                    <Button sx={{ width: 80, mx: 2 }} color="secondary" variant="contained" onClick={ () => handleClick() }>
                       {props.formMode === 'Create' ? 'Create': props.formMode === 'Update' ? 'Save': 'Create' }
                    </Button>
                    <Button sx={{ width: 80 }} type="button" color="secondary" variant="contained" onClick={ props.onClick }>cancel</Button>
                </div>
            </div>
        </Box>
    );
}
  