import FormComponent from '../components/form-component';
import TableComponent from '../components/table-component';
import React, { Fragment, useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { connect } from "react-redux";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';

const mapStateToProps = (state) => {
  return {
    members: state.members,
  };
};

export default connect(mapStateToProps, {})((props) => {
  const initialState = {
    isShowForm: false,
    formMode: 'Create'
  }
  
  const [formMode, setFormMode] = useState(initialState)
  const [toastr, setToastr] = useState({isShowSuccess: false, isShowError: false});
  const [formUpdate, setFormUpdate] = useState(null);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setToastr({isShowSuccess: false, isShowError: false, massage: ''})
  };

  return (
    <Fragment>
      <CssBaseline />
      <Container sx={{ my: '2em'}} fixed>
        <Box sx={{ minHeight: 'calc(100vh - 4em)', borderRadius: '10px', padding: '2em' }}>
          <FormComponent onClick={() => setFormMode(initialState)}  setToastr={setToastr} formMode={formMode.formMode} formUpdate={formUpdate}/>
          <TableComponent data={props} setFormMode={setFormMode} setFormUpdate={setFormUpdate} setToastr={setToastr}/>
        </Box>
        <Stack sx={{ width: '100%' }} spacing={2}>
          <Snackbar open={toastr.isShowError} autoHideDuration={6000} onClose={handleClose}>
            <Alert severity="error" onClose={handleClose}>{toastr.massage}</Alert> 
          </Snackbar>
          <Snackbar open={toastr.isShowSuccess} autoHideDuration={6000} onClose={handleClose}>
            <Alert severity="success" sx={{backgroundColor: "#fca7c9", color: '#A8577E' }} onClose={handleClose}>{toastr.massage}</Alert> 
          </Snackbar>
        </Stack>
      </Container>
    </Fragment>
  );
});
