import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { connect } from "react-redux";
import { retrieveMembers, deleteMember } from "../redux/actions/members";
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteIcon from '@mui/icons-material/Delete';
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default connect(null, { retrieveMembers, deleteMember })(CustomPaginationActionsTable);
function CustomPaginationActionsTable(props) {
  const navigate = useNavigate()
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const rows = props.data.members.sort((a, b) => (a.uid < b.uid ? -1 : 1));
  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const onDeleteMember = (memberId) => {
    props.deleteMember(memberId)
    .then( res => {
      props.setToastr({isShowSuccess: true, massage: res.status })
      setDeleteId(null)
    })
    .catch( err => {
      props.setToastr({isShowError: true, massage: err.status })
      setDeleteId(null)
    })
  }

  const [open, setOpen] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const handleClickOpen = (memberId) => {
    setOpen(true);
    setDeleteId(memberId)
  };
  const handleClose = (cf) => {
    if ( cf ) {
      onDeleteMember(deleteId)
    }
    setOpen(false);
  };
  
  const handleClick = (memberId) => {
    navigate('/member/'+ memberId)
  };
  
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Confirm Delete
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this member?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" sx={{ fontWeight: 'bold' }} onClick={() => handleClose(true) } autoFocus>
            Confirm
          </Button>
          <Button color="secondary" variant="contained" onClick={() => handleClose(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    <TableContainer component={Paper} sx={{ mt: 5, p: 2 }}>
      <Table sx={{ minWidth: 500 }} aria-label="custom pagination table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Id</TableCell>
            <TableCell align="left">
              <Stack direction="row" spacing={2}>
                <DriveFileRenameOutlineIcon sx={{ mr:1 }}/>Name
              </Stack>
            </TableCell>
            <TableCell align="left">
              <Stack direction="row" spacing={2}>
                <EmailIcon sx={{ mr:1 }}/>Email
              </Stack>
            </TableCell>
            <TableCell align="left">
              <Stack direction="row" spacing={2}>
                <PhoneAndroidIcon sx={{ mr:1 }}/>Contact
              </Stack>
              </TableCell>
            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row) => (
            <TableRow key={row.uid}>
              <TableCell component="th" scope="row" style={{ width: 40 }} align="left">
                {row.uid}
              </TableCell>
              <TableCell style={{ width: 200 }} align="left">
                {row.name}
              </TableCell>
               <TableCell style={{ width: 160 }} align="left">
                {row.email}
              </TableCell>
               <TableCell style={{ width: 100 }} align="left">
                {row.contact}
              </TableCell>
              <TableCell style={{ width: 140 }} align="right">
                  <Stack direction="row" spacing={2}>
                    <Button color="primary" variant="text" onClick={() => handleClick(row.id) }>
                      <VisibilityIcon sx={{ mr:1 }}/>View
                    </Button>
                    <Button color="primary" variant="text" onClick={() => { props.setFormMode({formMode: 'Update', isShowForm: true });props.setFormUpdate(row)}}>
                      <DriveFileRenameOutlineIcon sx={{ mr:1 }}/>Update
                    </Button>
                    <Button color="primary" variant="text" onClick={() => handleClickOpen(row.id) }>
                      <DeleteIcon sx={{ mr:1 }}/>Delete
                    </Button>
                  </Stack>
              </TableCell>
            </TableRow>
          ))}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
              colSpan={5}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
    </>
  );
}
