import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import store from './redux/store';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import AppBar from '@mui/material/AppBar';
import Typography from '@mui/material/Typography';

const textCustom = '#A8577E';
const theme = createTheme({
  palette: {
    background: {
      default: '#FFFFFF', // background
      paper: '#F5D7E3' // boxcard
    },
    primary: {
      main: textCustom, 
    },
    secondary: {
      main: '#A8577E', // buttom color
    },
    text: {
      primary: '#A8577E', // textcolor
      secondary: textCustom
    },
  },
});

function appBarLabel(label) {
  return (
    <Toolbar>
      <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
        {label}
      </Typography>
    </Toolbar>
  );
}

function MatTheme() {
  return (
    <ThemeProvider theme={theme}>
      <AppBar position="sticky" color="primary">
        {appBarLabel('My Contacts')}
      </AppBar>
      <App/>
    </ThemeProvider>
)}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <MatTheme/>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
reportWebVitals();
