import * as React from 'react';
import { Routes, Route } from "react-router-dom";
import Main from './pages/page-main';
import View from './pages/page-view';
import NotFound from './pages/page-not-found';
import Dotenv from 'dotenv';
import { connect } from "react-redux";
import { retrieveMembers } from "./redux/actions/members";

export default connect(null, { retrieveMembers })(App);
function App(props) {
  Dotenv.config()
  console.log("env", process.env);
  props.retrieveMembers();
  return (
    <React.Fragment>
      <Routes>
        <Route exact path="/" element={<Main/>}></Route>
        <Route path="/member/:id" element={<View/>}></Route>
        <Route path="*" element={<NotFound/>}></Route>
      </Routes>
    </React.Fragment>
  );
}