import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import KeyboardOptionKeyIcon from '@mui/icons-material/KeyboardOptionKey';
import BadgeIcon from '@mui/icons-material/Badge';
import EmailIcon from '@mui/icons-material/Email';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import Divider from '@mui/material/Divider';
import { retrieveMember } from "../services/google.firebase";
import Typography from '@mui/material/Typography';

export default function View() {
  const { id } = useParams();
  const [userId, setUserId] = React.useState('');
  const [userName, setUserName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [contact, setContact] = React.useState('');
  const fetchData = async () => {
    const apiCall = await retrieveMember(id)
    const data = await apiCall.data
    setUserId(data.uid)
    setUserName(data.name)
    setEmail(data.email)
    setContact(data.contact)
  }
  fetchData()
  return (
    <React.Fragment>
      <CssBaseline />
      <Container style={{ display: 'flex', justifyContent:'center' }} sx={{ my: "2em", width: '60%', }} fixed>
        <Box
          sx={{
            minHeight: "calc(100vh - 4em)",
            borderRadius: "50px",
            padding: "3em",
            width: '100%',
          }}
        >
          <List
            sx={{
              width: "100%",
              bgcolor: "background.paper",
            }}
          >
            <ListItem>
              <ListItemAvatar>
                <KeyboardOptionKeyIcon color="primary"/>
              </ListItemAvatar>
              <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                ID : {userId}
              </Typography>
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <BadgeIcon color="primary"/>
              </ListItemAvatar>
              <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                <span style={{ width: '100px', display: 'inline-block'}}>Name : </span>{userName}
              </Typography>
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <EmailIcon color="primary"/>
              </ListItemAvatar>
              <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
              <span style={{ width: '100px', display: 'inline-block'}}>Email : </span>{email}
              </Typography>
            </ListItem>
            <Divider variant="inset" component="li" />
            <ListItem>
              <ListItemAvatar>
                <PhoneAndroidIcon color="primary"/>
              </ListItemAvatar>
              <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
              <span style={{ width: '100px', display: 'inline-block'}}>Contact : </span>{contact}
              </Typography>
            </ListItem>
          </List>
          <Button sx={{ my: 4 }} color="secondary" variant="contained"><Link to="/" style={{ textDecoration: 'unset', color: 'unset', }}>Back</Link></Button>
        </Box>
      </Container>
    </React.Fragment>
  );
}
