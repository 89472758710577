import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";


export default function NotFound() {
  return (
    <React.Fragment>
      <CssBaseline />
      <Container sx={{ my: '2em'}} fixed>
        <Box sx={{ minHeight: 'calc(100vh - 4em)', borderRadius: '10px', padding: '5em' }}>
          <h1>Page not found :( </h1>
          <h5>Maybe the page you are looking for has been removed, or you typed in the wrong URL.</h5>
          <Stack spacing={2} direction="row">
              <Button variant="contained" color="secondary"><Link to="/" style={{ textDecoration: 'unset', color: 'unset' }}>Go to homepage</Link></Button>
          </Stack>
        </Box>
      </Container>
    </React.Fragment>
  );
}
