import { initializeApp } from "firebase/app";
import { getFirestore, doc, getDoc } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_FIREBASE_SORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
  appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);


export async function retrieveMember(id) {
  try {
    const docRef = doc(db, "members", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return Promise.resolve({ data: docSnap.data(), status: 'fetch success' });
    }
  } catch (error) {
    return Promise.reject({ err: error, status: 'fetch error'});
  }
};