import { db } from "../../services/google.firebase";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";

// types
export const CREATE_MEMBER = "CREATE_MEMBER";
export const RETRIEVE_MEMBERS = "RETRIEVE_MEMBERS";
export const RETRIEVE_MEMBER_BY_ID = "RETRIEVE_MEMBER_BY_ID";
export const UPDATE_MEMBER = "UPDATE_MEMBER";
export const DELETE_MEMBER = "DELETE_MEMBER";

export const membersCollectionRef = collection(db, "members");

export const createMember = (params) => async (dispatch) => {
  try {
    const data = await getDocs(membersCollectionRef);
    const mapData = await data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    const uid = Math.max.apply(Math, mapData.map(function(e) { return e.uid; }))
    const dataCreate = { ...params, complete: true, created: Date.now(), uid: uid !== -Infinity ? uid+1 : 1 }
    const { id } = await addDoc(membersCollectionRef, dataCreate);
    dispatch({
      type: CREATE_MEMBER,
      payload: {...dataCreate, id}
    });
    return Promise.resolve({...params, status: 'create success'});
  } catch (error) {
    return Promise.reject({ err: error, status: 'create error'});
  }
};

export const retrieveMembers = () => async (dispatch) => {
  try {
    const data = await getDocs(membersCollectionRef);
    const mapData = await data.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    dispatch({
      type: RETRIEVE_MEMBERS,
      payload: mapData,
    });
    return Promise.resolve({ status: 'fetch success' });
  } catch (error) {
    return Promise.reject({ err: error, status: 'fetch error'});
  }
};

export const updateMember = (id, data) => async (dispatch) => {
  try {
    const memberDoc = doc(db, "members", id);
    delete memberDoc['id'];
    await updateDoc(memberDoc, data);
    dispatch({
      type: UPDATE_MEMBER,
      payload: data,
    });
    return Promise.resolve({ ...data, status: 'update success'});
  } catch (error) {
    return Promise.reject({ err: error, status: 'update error'});
  }
};

export const deleteMember = (id) => async (dispatch) => {
  try {
    const memberDoc = doc(db, "members", id);
    await deleteDoc(memberDoc);
    dispatch({
      type: DELETE_MEMBER,
      payload: { id },
    });
    return Promise.resolve({ status: 'success'});
  } catch (error) {
    return Promise.reject({ err: error, status: 'error'});
  }
};

